import React, { ComponentProps } from 'react';

import { cn } from '@paytome.co/lib';

interface Props extends ComponentProps<'div'> {
  cardClass?: string;
}

export function AuthLayoutCard({ className, cardClass, children, ...rest }: Props) {
  return (
    <div className={cn('grid h-full lg:place-items-center', className)} {...rest}>
      <div className={cn('rounded-xl p-5 lg:shadow-2xl', cardClass)}>{children}</div>
    </div>
  );
}
