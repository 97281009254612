'use client';

import React, { useLayoutEffect, useState } from 'react';

import PhoneInput from '@paytome.co/shared/components/ui/phone-input/PhoneInput';
import { PhoneInputProps } from '@paytome.co/shared/components/ui/phone-input/types';
import { DefaultValues } from '@paytome.co/shared/constants';

export default function ViewPhoneInput({
  countries,
  disabled = false,
  required = false,
  phoneInfo: phoneInfoData,
  ...rest
}: Omit<PhoneInputProps, 'setPhoneInfo'>) {
  const [phoneInfo, setPhoneInfo] = useState(DefaultValues.phoneInfo);

  useLayoutEffect(() => {
    if (phoneInfoData?.phone_number) {
      setPhoneInfo({
        ...phoneInfo,
        iso2: phoneInfoData?.iso2,
        phone_number: phoneInfoData?.phone_number,
        phone_with_code: phoneInfoData?.phone_with_code,
      });
    } else {
      setPhoneInfo(DefaultValues.phoneInfo);
    }
  }, [phoneInfoData?.phone_number]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PhoneInput
      required={required}
      disabled={disabled}
      phoneInfo={phoneInfo}
      countries={countries ?? []}
      setPhoneInfo={setPhoneInfo}
      inputProps={{
        maxLength: ['us'].includes(phoneInfo.iso2.toLowerCase()) ? 16 : 15,
      }}
      {...rest}
    />
  );
}
