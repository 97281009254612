import React from 'react';

import useCountries from '@paytome.co/hooks/countries';
import { cn } from '@paytome.co/lib';
import { EditablePhoneInput, ViewPhoneInput } from '@paytome.co/shared/components';
import { CustomPhoneInputProps } from '@paytome.co/shared/components/ui/phone-input/types';
import { DefaultValues } from '@paytome.co/shared/constants';

export default function CustomPhoneInput({
  classes,
  className,
  mode = 'editable',
  disabled = false,
  required = false,
  setPhoneInfo = () => {
    console.log('empty');
  },
  phoneInfo = DefaultValues.phoneInfo,
  ...rest
}: CustomPhoneInputProps) {
  const { countries, isLoading } = useCountries();

  if (isLoading) {
    return (
      <div className={cn('mx-auto w-full rounded-md border border-purple-700 p-4 shadow', className)}>
        <div className='flex animate-pulse space-x-4'>
          <div className='flex-1'>
            <div className='h-2 rounded bg-slate-700'></div>
          </div>
        </div>
      </div>
    );
  }

  // Destructure editable variant props separately
  const { name, control, errors, actions, setValue, controlRest } = rest as CustomPhoneInputProps & {
    mode: 'editable';
  };

  return (
    <div className={cn('', className)}>
      {mode === 'view' ? (
        <ViewPhoneInput
          disabled
          classes={classes}
          required={required}
          countries={countries}
          phoneInfo={phoneInfo}
          {...rest}
        />
      ) : (
        <EditablePhoneInput
          name={name}
          control={control}
          errors={errors}
          actions={actions}
          setValue={setValue}
          controlRest={controlRest}
          classes={classes}
          disabled={disabled}
          required={required}
          countries={countries}
          phoneInfo={phoneInfo}
          setPhoneInfo={setPhoneInfo}
          {...rest}
        />
      )}
    </div>
  );
}
