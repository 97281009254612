const phone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const onlySmallLetters = /^[a-z_-]+$/;

const decimalAfterTwoDigits = /^\d*\.?\d{0,2}$/;

const lettersWithSpacesAndDot = /^[a-zA-Z\s.]*$/g;

const addHyphenBetweenCharsAndDigits = /^[a-z]+(-[0-9]+)?$/;

const username = /^[a-z][a-z][a-z][a-z][a-z][a-z]+(-[0-9][0-9]+)$/;

const leadingZeroOrDot = /^(0|[1-9][0-9]*)(\.[0-9]*[1-9])?$|^(\.[0-9]*[1-9])?$/;

const userName = /^(?=(.*\d){2})(?=.*[a-z])(?=(.*\d){2})(?=.*[!-])[0-9a-z!-]{9,}/;

const website =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[a-zA-Z0-9._~:/?#[\]@!$&'()*+,;=-]*)?$/;

export const RegularExpressions = {
  phone,
  email,
  website,
  username,
  userName,
  onlySmallLetters,
  leadingZeroOrDot,
  decimalAfterTwoDigits,
  lettersWithSpacesAndDot,
  addHyphenBetweenCharsAndDigits,
};
