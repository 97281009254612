'use client';

import React from 'react';

import { Controller } from 'react-hook-form';

import PhoneInput from '@paytome.co/shared/components/ui/phone-input/PhoneInput';
import { PhoneInfoProps, PhoneInputWithEditableProps } from '@paytome.co/shared/components/ui/phone-input/types';

export default function EditablePhoneInput({
  name,
  errors,
  control,
  actions,
  setValue,
  controlRest,
  required,
  disabled,
  countries,
  phoneInfo,
  setPhoneInfo,
  ...rest
}: PhoneInputWithEditableProps) {
  const hasError = !!errors?.[name];

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <PhoneInput
              required={required}
              disabled={disabled}
              hasError={hasError}
              phoneInfo={phoneInfo}
              countries={countries ?? []}
              setPhoneInfo={setPhoneInfo}
              onChange={(updatedPhoneInfo: PhoneInfoProps) => {
                onChange(updatedPhoneInfo.phone_number);

                if (actions) {
                  actions();
                }

                if (setValue) {
                  setValue(name, updatedPhoneInfo.phone_number);
                }
              }}
              inputProps={{
                maxLength: ['us'].includes(phoneInfo.iso2.toLowerCase()) ? 16 : 15,
              }}
              {...rest}
            />
          );
        }}
        {...controlRest}
      />
      {hasError && (
        <p className={'text-red-primary mt-1 text-xs'} id={name}>
          {errors?.[name]?.message}
        </p>
      )}
    </>
  );
}
