import Link from 'next/link';
import React, { HTMLAttributes } from 'react';

import { cn } from '@paytome.co/lib';

interface PrimaryLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href?: string;
  disabled?: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
}

export function PrimaryLink({ href = '#', className, disabled, children, ...rest }: PrimaryLinkProps) {
  return (
    <Link
      href={href}
      onClick={e => disabled && e.preventDefault()}
      className={cn(
        `hover:border-blue-primary text-blue-primary w-fit border-b-2 border-transparent font-bold leading-[normal] transition-all duration-150`,
        className,
        { 'border-gray-primary text-gray-primary cursor-not-allowed': disabled }
      )}
      {...rest}
    >
      {children}
    </Link>
  );
}
