const cleanPhoneNumber = (phoneNumberString: string): string => phoneNumberString.replace(/\D/g, '');

const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = cleanPhoneNumber(phoneNumberString);

  // Split into blocks of 3, 3, and remaining characters
  const part1 = cleaned.slice(0, 3);
  const part2 = cleaned.slice(3, 6);
  const part3 = cleaned.slice(6);

  // Build the final formatted string with appropriate spaces
  let formattedNumber = part1;
  if (part2) formattedNumber += ` ${part2}`;
  if (part3) formattedNumber += ` ${part3}`;

  return formattedNumber;
};

const formatPhoneNumberInGroupsOfThree = (phoneNumberString: string): string => {
  const cleaned = cleanPhoneNumber(phoneNumberString);

  // Add a space after every 3 digits using a regular expression
  return cleaned.replace(/(\d{3})(?=\d)/g, '$1 ');
};

const formatPhoneNumberForUs = (phoneNumberString: string): string => {
  const cleaned = cleanPhoneNumber(phoneNumberString);
  const len = cleaned.length;

  if (len === 0) return '';
  if (len < 4) return `(${cleaned}`; // Handle input as (1, (12, (123
  if (len < 7) return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`; // (123) 4 or (123) 45
  if (len === 7) return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)} - ${cleaned.slice(6)}`; // (123) 456 - 7

  // Handle cases with 10 or more digits
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)} - ${cleaned.slice(6, 10)}`;
};

const formatPhoneNumberByIso2 = (phoneNumberString: string, countryIso2 = 'us'): string => {
  const countryFormatters: Record<string, (phone: string) => string> = {
    us: formatPhoneNumberForUs,
  };

  const formatter = countryFormatters[countryIso2.toLowerCase()] || formatPhoneNumber;

  return formatter(phoneNumberString);
};

function removeCallingCode(phone_with_code: string, calling_code?: string): string {
  // Remove the calling code if it exists in the phone number
  if (calling_code && phone_with_code.startsWith(`+${calling_code}`)) {
    return phone_with_code.slice(calling_code.length + 1);
  } else if (calling_code && phone_with_code.startsWith(calling_code)) {
    return phone_with_code.slice(calling_code.length);
  }

  return phone_with_code;
}

function calculateCursorPos(originalValue: string, formattedNumber: string, originalCursorPos: number): number {
  // Clean the numbers from both the original and formatted values
  const cleanedOriginal = cleanPhoneNumber(originalValue);
  const cleanedFormatted = cleanPhoneNumber(formattedNumber);

  // Calculate the difference in length between cleaned and formatted values
  const originalDiff = originalValue.length - cleanedOriginal.length;
  const formattedDiff = formattedNumber.length - cleanedFormatted.length;

  // Adjust the cursor position based on the difference in non-digit characters
  const spaceDifference = formattedDiff - originalDiff;

  return originalCursorPos + spaceDifference;
}

export {
  cleanPhoneNumber,
  formatPhoneNumber,
  removeCallingCode,
  calculateCursorPos,
  formatPhoneNumberForUs,
  formatPhoneNumberByIso2,
  formatPhoneNumberInGroupsOfThree,
};
