/**
 * Author: Hridoy Ahmed
 * Website: hridoy.dev
 * Email: learnwithhridoy@gmail.com
 * Updated Date: 18 November 2024
 */
import { CONFIG, fetchCsrfToken, getCookie, goToDirectory, removeCookies } from '@paytome.co/shared';
import { KeyValuePairs } from '@paytome.co/type';

/**
 * Retrieves the authentication token from cookies or local storage.
 */
export const getAuthToken = (): string | null => {
  return getCookie(CONFIG.authToken) || null;
};

/**
 * Fetches CSRF token if required for the HTTP method.
 */
export const getCsrfToken = async (method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'): Promise<string | null> => {
  const requiresCsrf = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) && CONFIG.isCsrfRequired;

  if (requiresCsrf && !(await fetchCsrfToken())) {
    console.warn('CSRF Token not fetched, aborting request');
    return null;
  }

  return requiresCsrf ? getCookie('XSRF-TOKEN') : null;
};

/**
 * Clears authentication-related data and redirects to login page.
 */
export const redirectAfterLogout = (): void => {
  removeCookies([CONFIG.authToken, 'XSRF-TOKEN']);
  goToDirectory(`${CONFIG.authUrl}/login`);
};

/**
 * Sends a JSON request to the given API endpoint.
 */

type RequestConfig = {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  payload?: KeyValuePairs | FormData;
  queryParams?: string;
  apiBaseUrl?: string;
  apiVersion?: string;
  willRedirect?: boolean;
};

type ResponseData = {
  data: KeyValuePairs;
  statusCode: number;
};

/**
 * Constructs the full URL based on base URL, API version, endpoint, and query parameters.
 */
const constructUrl = ({
  url,
  queryParams,
  apiBaseUrl,
  apiVersion,
}: Pick<RequestConfig, 'url' | 'queryParams' | 'apiBaseUrl' | 'apiVersion'>): string => {
  const baseUrl = `${apiBaseUrl}/${apiVersion}`;
  const fullUrl = `${baseUrl}${url}`;
  return queryParams ? `${fullUrl}${queryParams}` : fullUrl;
};

/**
 * Prepares the headers for the request, including Authorization and CSRF token if available.
 */
const prepareHeaders = async (
  method: RequestConfig['method'] = 'GET',
  isFormData: boolean
): Promise<Record<string, string>> => {
  const token = getAuthToken();
  const csrfToken = await getCsrfToken(method);

  return {
    Accept: 'application/json',
    ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(csrfToken ? { 'X-Xsrf-Token': csrfToken } : {}),
  };
};

/**
 * Sends a request to the given API endpoint, supporting both JSON and file payloads.
 */
export const sendRequest = async ({
  url,
  payload = {},
  queryParams = '',
  method = 'GET',
  apiBaseUrl = CONFIG.apiBaseUrl,
  apiVersion = 'v1',
  willRedirect = true,
}: RequestConfig): Promise<ResponseData> => {
  try {
    const fullUrl = constructUrl({ url, queryParams, apiBaseUrl, apiVersion });
    const isFormData = payload instanceof FormData;
    const headers = await prepareHeaders(method, isFormData);

    const response = await fetch(fullUrl, {
      method,
      headers,
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include',
      referrerPolicy: 'origin',
      body: ['GET', 'DELETE'].includes(method) ? undefined : isFormData ? payload : JSON.stringify(payload),
    });

    if (response.status === 401 && willRedirect) redirectAfterLogout();

    const data = await response.json();
    return { data, statusCode: response.status };
  } catch (error) {
    console.error(`Error in sendRequest: ${error}`);
    return { data: null, statusCode: 0 };
  }
};

/**
 * Sends a file upload request to the given API endpoint.
 */
export const sendFileRequest = async ({
  method = 'POST',
  url,
  payload,
  willRedirect = true,
}: {
  method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  payload: FormData;
  willRedirect?: boolean;
}): Promise<{ data: unknown; statusCode: number }> => {
  try {
    const token = getAuthToken();
    const csrfToken = await getCsrfToken(method);

    const response = await fetch(url, {
      method,
      body: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(csrfToken ? { 'X-Xsrf-Token': csrfToken } : {}),
      },
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include',
      referrerPolicy: 'origin',
    });

    if (response.status === 401 && willRedirect) redirectAfterLogout();

    const data = await response.json();
    return { data, statusCode: response.status };
  } catch (error) {
    console.error(`Error in sendFileRequest: ${error}`);
    return { data: null, statusCode: 0 };
  }
};
