const phoneInfo = { iso2: 'us', phone_number: '', phone_with_code: '1' };

const country = {
  country_id: 226,
  name: 'United States (USA)',
  iso2: 'US',
  iso3: 'USA',
  calling_code: '1',
};

const state = {
  state_id: 0,
  state: '',
  s_code: '',
};

export const DefaultValues = {
  state,
  country,
  phoneInfo,
};
