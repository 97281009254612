import { cn } from '@paytome.co/lib';
import { IHelperText } from '@paytome.co/shared/components';

export const HelperText = ({ hasError, message, className }: IHelperText) => {
  if (!message) return;

  return (
    <p
      aria-label={'Error Message'}
      className={cn('text-red-primary text-xs font-normal sm:text-sm', { 'text-green-primary': !hasError }, className)}
    >
      {message}
    </p>
  );
};
