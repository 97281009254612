'use client';

import { cn } from '@paytome.co/lib';
import { CountryFlag, PhoneCountrySelect, usePhoneInput } from '@paytome.co/shared/components';
import { DropdownIcon } from '@paytome.co/shared/components/ui/phone-input/assets/Icons';
import { PhoneInputProps } from '@paytome.co/shared/components/ui/phone-input/types';

const PhoneInput = ({
  countries,
  phoneInfo,
  setPhoneInfo,
  inputProps,
  classes = {},
  hasError = false,
  disabled = false,
  required = false,
  showFromTop = false,
  label = 'Phone Number',
  onChange,
}: PhoneInputProps) => {
  const {
    isOpen,
    toggleDropdown,
    handlePhoneInput,
    selectCountry,
    searchedCountry,
    filteredCountries,
    handleSearchCountry,
    dropdownRef,
    phoneInputRef,
    selectedCountryRef,
  } = usePhoneInput(countries ?? [], phoneInfo, setPhoneInfo, onChange, hasError);

  return (
    <div
      title={disabled ? 'Disabled' : ''}
      className={cn('phone-input-wr w-full', { 'cursor-not-allowed': disabled }, classes.phoneInputWr)}
    >
      <fieldset className={cn('phone-input-fieldset group relative', classes.fieldset)}>
        <label
          className={cn(
            'phone-input-label absolute -top-2.5 left-2 z-40 px-1',
            'bg-white text-[13px] font-normal text-purple-700 group-focus-within:font-semibold',
            { 'cursor-not-allowed': disabled },
            { 'text-red-primary': hasError },
            classes.label
          )}
        >
          {label}
          <span className={cn('phone-input-label--required', classes.labelRequired)}>{required && '*'}</span>
        </label>
        <div
          className={cn(
            'phone-input-with-flag grid items-center',
            'focus-within:ring-1 focus-within:ring-purple-700',
            'divide-x divide-purple-700 rounded-md border border-purple-700 group-focus-within:divide-x-2',
            { 'grid-cols-[auto_minmax(50px,1fr)]': !!phoneInfo?.selected_country?.iso2 },
            { 'cursor-not-allowed': disabled },
            { 'divide-red-primary border-red-primary focus-within:ring-red-primary': hasError },
            classes.inputWithFlag
          )}
        >
          {!!phoneInfo?.selected_country?.iso2 && (
            <button
              type={'button'}
              disabled={disabled}
              aria-expanded={isOpen}
              aria-haspopup='listbox'
              onClick={toggleDropdown}
              className={cn(
                'phone-input-flag-button flex items-center space-x-1 bg-transparent px-2 py-2',
                { 'cursor-not-allowed': disabled },
                classes.flagButton
              )}
            >
              <CountryFlag iso2={phoneInfo?.selected_country?.iso2} className={cn('phone-input-flag', classes.flag)} />
              <span className={cn('phone-input-calling-code text-sm font-medium text-black', classes.callingCode)}>
                +{phoneInfo?.selected_country?.calling_code}
              </span>

              <DropdownIcon className={cn('', classes.dropdownIcon)} />
            </button>
          )}

          <input type='tel' hidden name='hidden-phone-input' />

          <input
            type='tel'
            id='phone-number'
            autoComplete='o-f-f' // To Avoid autocomplete
            ref={phoneInputRef}
            disabled={disabled}
            placeholder='e.g: 123456789'
            onChange={handlePhoneInput}
            value={phoneInfo?.formatted_phone_number ?? ''}
            aria-label='Phone number input'
            className={cn(
              'phone-input bg-transparent px-2 py-2',
              'text-sm font-medium text-black placeholder:font-normal focus:outline-none',
              { 'cursor-not-allowed': disabled },
              classes.input
            )}
            {...inputProps}
          />
        </div>
      </fieldset>

      {isOpen && (
        <PhoneCountrySelect
          classes={classes}
          phoneInfo={phoneInfo}
          showFromTop={showFromTop}
          dropdownRef={dropdownRef}
          selectCountry={selectCountry}
          searchedCountry={searchedCountry}
          filteredCountries={filteredCountries}
          selectedCountryRef={selectedCountryRef}
          handleSearchCountry={handleSearchCountry}
        />
      )}
    </div>
  );
};

export default PhoneInput;
