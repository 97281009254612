'use client';

import React, { Ref, forwardRef, useEffect } from 'react';

import { cn } from '@paytome.co/lib';
import { CountryFlag } from '@paytome.co/shared/components';
import { PhoneCountryPopupProps } from '@paytome.co/shared/components/ui/phone-input/types';

export const PhoneCountrySelect = forwardRef(
  (
    {
      classes,
      searchedCountry,
      phoneInfo,
      dropdownRef,
      selectCountry,
      filteredCountries,
      selectedCountryRef,
      handleSearchCountry,
      showFromTop = false,
    }: PhoneCountryPopupProps,
    ref: Ref<HTMLDivElement>
  ) => {
    // Scroll selected country into view when the dropdown opens
    useEffect(() => {
      if (selectedCountryRef?.current) {
        selectedCountryRef.current.scrollIntoView({ block: 'center' });
      }
    }, [selectedCountryRef]);

    return (
      <div className={cn('relative', classes?.flagDropdownWr)} ref={ref}>
        <div
          className={cn(
            'dropdown absolute left-0 top-full z-10 mt-2 w-full rounded-md border bg-white p-2 shadow-lg drop-shadow-lg',
            { '-top-[310px] bottom-[calc(100%+50px)]': showFromTop },
            classes?.flagSearchWr
          )}
          ref={dropdownRef}
        >
          <input
            type='search'
            autoFocus={true}
            value={searchedCountry}
            autoComplete='search-country'
            onChange={handleSearchCountry}
            placeholder='Search Your Country'
            className='mb-2 w-full rounded-md border px-2 py-1'
          />

          {filteredCountries.length > 0 ? (
            <ul role='listbox' className='mt-2 max-h-48 overflow-y-auto border-t pt-2'>
              {filteredCountries.map(country => {
                const selected = phoneInfo?.selected_country?.iso2 === country.iso2;

                return (
                  <li
                    role='option'
                    key={country.iso2}
                    className={cn('flex cursor-pointer items-center p-2 hover:bg-gray-100', {
                      'bg-gray-200': selected,
                    })}
                    ref={selected ? selectedCountryRef : null}
                    onMouseDown={() => selectCountry(country)}
                    aria-selected={selected}
                  >
                    <CountryFlag iso2={country.iso2} className={cn('mr-2 h-auto w-[25px]', classes?.flag)} />
                    <span className={cn('text-sm font-medium', classes?.callingCode)}>
                      {country.name} <span className='text-gray-500'>+{country.calling_code}</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className='py-2 text-center text-gray-500'>No Country Found!</div>
          )}
        </div>
      </div>
    );
  }
);
