'use client';

import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  calculateCursorPos,
  cleanPhoneNumber,
  formatPhoneNumberByIso2,
  removeCallingCode,
} from '@paytome.co/shared/helpers';

import { CountryProps, PhoneInfoProps, SetPhoneInfoProps, UsePhoneInputReturn } from './types';

export const usePhoneInput = (
  countries: CountryProps[],
  phoneInfo: PhoneInfoProps,
  setPhoneInfo: SetPhoneInfoProps,
  onChange?: (updatedPhoneInfo: PhoneInfoProps) => void,
  hasError?: boolean
): UsePhoneInputReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchedCountry, setSearchedCountry] = useState('');

  const dropdownRef = useRef<HTMLDivElement>(null);
  const phoneInputRef = useRef<HTMLInputElement | null>(null);
  const selectedCountryRef = useRef<HTMLLIElement | null>(null);

  const defaultCountry = useMemo(() => {
    if (countries.length && phoneInfo?.iso2) {
      return countries.find(country => country?.iso2?.toLowerCase() === phoneInfo.iso2.toLowerCase());
    }

    return null;
  }, [countries, phoneInfo?.iso2]);

  useEffect(() => {
    if (hasError && phoneInputRef && phoneInputRef?.current) {
      phoneInputRef.current.focus();
    }
  }, [hasError]);

  useEffect(() => {
    if (defaultCountry) {
      setPhoneInfo(prev => ({
        ...prev,
        selected_country: defaultCountry,
      }));
    }

    if (phoneInfo?.phone_with_code) {
      setPhoneInfo(prev => {
        const number = removeCallingCode(phoneInfo?.phone_with_code, prev.selected_country?.calling_code);

        return {
          ...prev,
          phone_number: number,
          formatted_phone_number: formatPhoneNumberByIso2(number, prev.iso2),
        };
      });
    } else if (phoneInfo?.phone_number) {
      setPhoneInfo(prev => ({
        ...prev,
        formatted_phone_number: formatPhoneNumberByIso2(prev?.phone_number, prev.iso2),
      }));
    }
  }, [defaultCountry, phoneInfo?.phone_with_code, phoneInfo?.phone_number]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDropdown = useCallback(() => {
    // Don't remove setTimeout. If you remove: phone input focus and toggle dropdown will not work properly.
    setTimeout(() => {
      setIsOpen(false);
      setSearchedCountry('');

      // Focus on the phone number input
      if (phoneInputRef.current && !isOpen) {
        phoneInputRef.current.focus();
      }
    }, 0); // You can adjust the timeout duration if needed
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // To close the dropdown on outside click.
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    },
    [closeDropdown]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleDropdown = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const selectCountry = useCallback(
    (country: CountryProps) => {
      // Update phone number in parent state
      setPhoneInfo(prev => {
        let number = phoneInfo.phone_number;

        if (['us'].includes(country.iso2.toLowerCase())) {
          number = cleanPhoneNumber(number).slice(0, 10);
        }

        const formattedNumber = formatPhoneNumberByIso2(number, country.iso2);

        const updatedPhoneInfo: PhoneInfoProps = {
          ...prev,
          iso2: country.iso2,
          selected_country: country,
          phone_number: number,
          formatted_phone_number: formattedNumber,
          phone_with_code: `${country.calling_code}${number}`,
        };

        // Call the optional handler if provided
        if (onChange) {
          onChange(updatedPhoneInfo);
        }

        return updatedPhoneInfo;
      });

      closeDropdown();
    },
    [phoneInfo, setPhoneInfo] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePhoneInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, selectionStart } = e.target;

      const numericValue = value.replace(/[^0-9]/g, '');

      // Keep track of the cursor position before formatting
      const originalCursorPos = selectionStart ?? value.length;

      // Update phone number in parent state
      setPhoneInfo(prev => {
        // Format the input value
        const formattedNumber = formatPhoneNumberByIso2(numericValue, prev?.selected_country?.iso2);

        const updatedPhoneInfo: PhoneInfoProps = {
          ...prev,
          phone_number: numericValue,
          iso2: prev?.selected_country?.iso2 ?? '',
          formatted_phone_number: formattedNumber,
          phone_with_code: `${prev?.selected_country?.calling_code}${numericValue}`, // Update full phone number
        };

        // Call the optional handler if provided
        if (onChange) {
          setTimeout(() => {
            onChange(updatedPhoneInfo);
          }, 0);
        }

        // Restore the cursor position considering the formatting changes
        window.requestAnimationFrame(() => {
          const newCursorPos = calculateCursorPos(value, formattedNumber, originalCursorPos);
          e.target.setSelectionRange(newCursorPos, newCursorPos);
        });

        return updatedPhoneInfo;
      });
    },
    [setPhoneInfo, onChange]
  );

  const handleSearchCountry = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedCountry(e.target.value);
  };

  // Filter countries based on the search input
  const filteredCountries = useMemo(() => {
    return countries.length > 0
      ? countries.filter(country => country?.name?.toLowerCase()?.includes(searchedCountry?.toLowerCase()))
      : [];
  }, [countries, searchedCountry]);

  return {
    isOpen,
    selectCountry,
    toggleDropdown,
    handlePhoneInput,
    filteredCountries,
    searchedCountry,
    handleSearchCountry,
    dropdownRef,
    phoneInputRef,
    selectedCountryRef,
  };
};
