import React from 'react';

import { cn } from '@paytome.co/lib';

/*
 * Don't use the next image here.
 * So that it can be used React projects as well.
 * */

interface Props {
  iso2?: string;
  className?: string;
  extension?: 'svg' | 'jpeg' | 'webp' | 'png';
}

const MISSING_FLAGS: Record<string, string> = {
  ns: 'https://api.paytome.co/phone-input/flags/Saba.svg',
  wa: 'https://api.paytome.co/phone-input/flags/Wales.svg',
  ap: 'https://api.paytome.co/phone-input/flags/Azores.svg',
  ce: 'https://api.paytome.co/phone-input/flags/Canary.svg',
  kv: 'https://api.paytome.co/phone-input/flags/Kosobo.svg',
  sp: 'https://api.paytome.co/phone-input/flags/Saipan.svg',
  en: 'https://api.paytome.co/phone-input/flags/England.svg',
  te: 'https://api.paytome.co/phone-input/flags/Ireland.svg',
  ab: 'https://api.paytome.co/phone-input/flags/Scotland.svg',
  js: 'https://api.paytome.co/phone-input/flags/Somaliland.svg',
};

export function CountryFlag({ iso2, className, extension = 'svg' }: Props) {
  if (!iso2) return;

  const lowerIso2 = iso2?.toLowerCase();

  return (
    <img
      id={iso2}
      alt={iso2}
      className={cn('h-[15px] w-auto', className)}
      src={MISSING_FLAGS[lowerIso2] ?? `https://flagcdn.com/${lowerIso2}.${extension}`}
    />
  );
}
