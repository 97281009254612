import { ButtonHTMLAttributes, HTMLProps } from 'react';

import { VariantProps, cva } from 'class-variance-authority';

import { LoaderCircle } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';

const buttonVariants = cva(
  'flex items-center justify-center gap-2 rounded-md border-0 px-6 text-base font-semibold outline-0 transition-colors duration-200 shadow-sm',
  {
    variants: {
      variant: {
        primary: 'bg-blue-primary text-white',
        secondary: 'bg-indigo-600 text-white',
        danger: 'bg-red-500 text-white',
        success: 'bg-green-primary text-white',
        plain: 'min-h-[unset] bg-transparent p-0 text-sm underline text-gray-primary',
        disabled: '!bg-gray-400 text-white cursor-not-allowed',
      },
      loading: {
        true: '',
        false: '',
      },
      size: {
        default: 'min-h-[40px] px-6',
        small: 'min-h-[32px] px-4 text-sm',
        large: 'min-h-[48px] px-8 text-lg',
        unset: 'min-h-[unset] p-0',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
    compoundVariants: [
      {
        variant: 'plain',
        loading: true,
        className: 'text-gray-500',
      },
      {
        variant: 'disabled',
        loading: true,
        className: 'bg-gray-300',
      },
    ],
  }
);

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  loading?: boolean;
  placement?: 'left' | 'right';
  loadingIconClasses?: HTMLProps<HTMLElement>['className'];
}

export function Button({
  children,
  disabled,
  loading,
  variant = 'primary',
  size = 'default',
  className,
  loadingIconClasses,
  placement = 'left',
  ...rest
}: ButtonProps) {
  const isDisabled = disabled || loading;

  const ICON = <LoadingSpinner loading={loading} variant={variant} loadingIconClasses={loadingIconClasses} />;

  return (
    <button
      disabled={isDisabled}
      className={cn(
        buttonVariants({ variant: isDisabled ? 'disabled' : variant, size, loading: !!loading }),
        className
      )}
      {...rest}
    >
      {placement === 'right' ? (
        <>
          {children} {ICON}
        </>
      ) : (
        <>
          {ICON} {children}
        </>
      )}
    </button>
  );
}

const LoadingSpinner = ({
  loading,
  variant,
  loadingIconClasses,
}: Pick<ButtonProps, 'loading' | 'variant' | 'loadingIconClasses'>) => {
  if (!loading) return null;

  return (
    <LoaderCircle
      className={cn('h-5 w-5 animate-spin text-white', loadingIconClasses, {
        'text-gray-primary': variant === 'plain' && loading,
      })}
    />
  );
};
