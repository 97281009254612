export const isPhoneInLength = (iso2?: string | null) => {
  return iso2 ? ['us'].includes(iso2) : false;
};
export const SCHEMA_INPUT_LENGTH: Readonly<
  Record<string, { MIN: number; MAX: number; MAX_DECIMAL?: number; MAX_VALUE?: number }>
> = Object.freeze({
  // System Default
  DEFAULT: { MIN: 2, MAX: 100 }, // From QA
  OTP: { MIN: 6, MAX: 6 }, // From QA
  NAME: { MIN: 2, MAX: 35 }, // From QA
  BUSINESS_EIN_SSN: { MIN: 9, MAX: 9 },
  ZIP_CODE: { MIN: 4, MAX: 10 }, // From QA
  CITY: { MIN: 2, MAX: 35 }, // From QA
  COUNTRY: { MIN: 3, MAX: 70 },
  EMAIL: { MIN: 3, MAX: 50 }, // From QA
  WEBSITE: { MIN: 5, MAX: 50 }, // From QA
  ADDRESS: { MIN: 3, MAX: 35 }, // From QA
  PHONE: { MIN: 7, MAX: 15 }, // From QA
  MESSAGE: { MIN: 3, MAX: 200 }, // From QA
  DESCRIPTION: { MIN: 2, MAX: 200 }, // From QA
  ITEM_DESCRIPTION: { MIN: 2, MAX: 80 }, // From QA
  TAX: { MAX_DECIMAL: 3, MAX_VALUE: 999.999, MIN: 0, MAX: 0 }, // From Mike
  //   Exceptional Case
  COMPANY_TERMS: { MIN: 3, MAX: 200 }, // From QA
  COMPANY_NAME: { MIN: 2, MAX: 30 },
  COMPANY_DBA: { MIN: 3, MAX: 35 },
  INVOICE_ITEM: { MIN: 3, MAX: 84 },
  INVOICE_NUMBER: { MIN: 3, MAX: 30 },
  PO_NUMBER: { MIN: 3, MAX: 30 },
  PAYMENT_ID: { MIN: 4, MAX: 64 }, // From QA
  ATTENTION_TO: { MIN: 3, MAX: 30 },
  BANK_ACCOUNT_NUM: { MIN: 9, MAX: 18 }, // From QA
  QUANTITY_NUM: { MIN: 0, MAX: 999999 },
  SOCIAL_SECURITY_NUM: { MIN: 0, MAX: 9 }, // From QA
  NID_OR_TAX_NUM: { MIN: 7, MAX: 30 }, // From QA
  ROUTING_NUM: { MIN: 5, MAX: 9 }, // From QA

  SPLIT_QUANTITY_NUM: { MIN: 0, MAX: 25 },
});
