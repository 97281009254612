import { SVGProps } from 'react';

import { cn } from '@paytome.co/lib';

export function DropdownIcon({ className, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-purple-orchid h-4 w-4', className)}
      viewBox='0 0 20 20'
      fill='currentColor'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
        clipRule='evenodd'
      />
    </svg>
  );
}
