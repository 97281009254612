import { CONFIG } from '@paytome.co/shared';

const csrfUrl = `${CONFIG?.apiBaseUrl?.replace('co/api', 'co')}/sanctum/csrf-cookie`;

// Function to fetch the CSRF token
export const fetchCsrfToken = async () => {
  try {
    const response = await fetch(csrfUrl, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      referrerPolicy: 'origin',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch CSRF token');
    }

    return true;
  } catch (error) {
    console.warn(`CSRF Token Fetch Error: ${error}`);
    return false;
  }
};
